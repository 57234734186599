
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.inner {
    color: #000;

    @apply mx-auto px-5 lg:max-w-7xl flex flex-col  justify-between items-center;
   
}
