
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.wrapper {
    position: relative;

    h2 {
        font-size: 24px;
        line-height: 30px;
        font-weight: bold;

        @include lg {
            font-size: 30px;
            line-height: 45px;
        }
    }

    p {
        font-size: 16px;
        line-height: 27px;

        margin-top: 30px;

        @include lg {
            font-size: 18px;
            line-height: 32px;
        }
    }
}
.wrapperBackground {
    position: relative;
    &::before {
        content: "";
        left: 50%;
        width: 100vw;
        transform: translateX(-50%);
        height: 100%;
        position: absolute;
        z-index: -1;

        background-color: #f9f9f9;
    }
}
